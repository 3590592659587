@import "scss/global/base/variables.scss";

.JoinBusinessTutorial {
	max-width: 600px;
	margin: 0 auto;
	padding: $space-external;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__steps {
		margin: $space-large 0;
		display: flex;
		gap: $space-small;
		&__item {
			width: 10px;
			height: 10px;
			background: #D9D9D9;
			border-radius: 50%;
			&.active {
				background: #0078F4;
			}
		}
	}

	&__title {
		margin-top: $space-medium;
		margin-bottom: $space-large;
	}

	&__description {
		margin-bottom: $space-large;
		text-align: center;
		line-height: 1.5;
		font-size: $font-size-msmall;
	}

	&__next {
		width: 100%;
	}
}