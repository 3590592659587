.loader {
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin: 0;
  position: fixed;
  top: 0;
  background:rgba(255,255,255,0.8);
  z-index:100;
  &__filter{
    position: absolute;
    visibility: hidden;
  }
  &__dots{
    filter: url(#gooeyness);
    padding: 30px;
  }
  &__dot{
    background:#00B0CD;
    border-radius: 50%;
    display: inline-block;
    margin-right: 20px;
    width: 32px;
    height: 32px;
    position:relative;
    will-change:transform;
    &:first-child{
      animation: FirstDot 3s infinite;
      z-index:1;
    }
    &:nth-child(2){
      animation: SecondDot 3s infinite;
      background:#9B2272
    }
    &:nth-child(3) {
      animation: ThirdDot 3s infinite;
      background:orange
    }
    &:nth-child(4) {
      animation: FourthDot 3s infinite;
      background:#E73838;
    }
    &:nth-child(5) {
      animation: FifthDot 3s infinite;
    }
  }
  &__img{
    width:33px;
    height:33px;
    position:absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
  }

  &__first-img{
    animation: FirstImg 3s infinite;
  }
  &__second-img{
    animation: FifthImg 3s infinite;;
  }
}

@keyframes FirstImg {
  0% {
    opacity:0;
  }
  25% {
    opacity:1;
  }
  50% {
    opacity:0;
  }
  83% {
    opacity:0;
  }
  100% {
    opacity:0;
  }
}

@keyframes FifthImg {
  0% {
    opacity:0;
  }
  25% {
    opacity:0;
  }
  50% {
    opacity:0;
  }
  83% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@keyframes FirstDot {
  0% {
    transform: scale(1) translateX(0);
    background:#00B0CD;
  }
  25% {
    transform: scale(2.5) translateX(0);
    background:white;
  }
  50% {
    transform: scale(1) translateX(0);
    background:#00B0CD;
    z-index:1;
  }
  83% {
    transform: scale(1) translateX(240px);
    z-index:0;
  }
  100% {
    transform: scale(1) translateX(0);
    z-index:1;
  }
}

@keyframes SecondDot {
  0% {
    transform: translateX(0px);
  }
  27% {
    transform: translateX(-40px);
  }
  50% {
    transform: translateX(0px);
  }
  81% {
    transform: translateX(180px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes ThirdDot {
  0% {
    transform: translateX(0px);
  }
  29% {
    transform: translateX(-100px);
  }
  50% {
    transform: translateX(0px);
  }
  79% {
    transform: translateX(120px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes FourthDot {
  0% {
    transform: translateX(0px);
  }
  31% {
    transform: translateX(-160px);
  }
  50% {
    transform: translateX(0px);
  }
  77% {
    transform: translateX(60px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes FifthDot {
  0% {
    transform: scale(1) translateX(0);
  }
  33% {
    transform: scale(1) translateX(-220px);
  }
  50% {
    transform: scale(1) translateX(0);
    background:#00B0CD;
  }
  75% {
    transform: scale(2.5) translateX(0);
    background:white;
  }
  100% {
    transform: scale(1) translateX(0);
    background:#00B0CD;
  }
}
