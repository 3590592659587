@import "scss/global/base/variables.scss";

.ely-search {
	position: relative;
	&__icon {
		position: absolute;
		left: $space-smaller;
		top: 0;
		bottom: 0;
	}
	&__input {
		width: 100%;
		border: none;
		border-radius: 5px;
		box-shadow: $box-shadow;
		padding: $space-smaller $space-smaller $space-smaller 35px;
	}
}