@import "scss/global/base/variables.scss";

.ely-emote {
	display: inline-flex;
	align-items: center;
	font-size: 0;

	&:before {
		content: '';
		background-size: contain;
		background-repeat: no-repeat;
	}

	&--standard {
		&.show-title {
			font-size: $font-size-medium;
		}
		&:before {
			width: 34px;
			height: 34px;
		}
	}
	&--small {
		&.show-title {
			font-size: $font-size-small;
		}
		&:before {
			width: 18px;
			height: 18px;
		}
	}

	&--adventurous:before {
		background-image: url("~img/feeling/icon-adventurous.svg");
	}

	&--amazed:before {
		background-image: url("~img/feeling/icon-amazed.svg");
	}

	&--amused:before {
		background-image: url("~img/feeling/icon-amused.svg");
	}

	&--angry:before {
		background-image: url("~img/feeling/icon-angry.svg");
	}

	&--awkward:before {
		background-image: url("~img/feeling/icon-awkward.svg");
	}

	&--bored:before {
		background-image: url("~img/feeling/icon-bored.svg");
	}

	&--calm:before {
		background-image: url("~img/feeling/icon-calm.svg");
	}

	&--confused:before {
		background-image: url("~img/feeling/icon-confused.svg");
	}

	&--craving:before {
		background-image: url("~img/feeling/icon-craving.svg");
	}

	&--disappointed:before {
		background-image: url("~img/feeling/icon-disappointed.svg");
	}

	&--excited:before {
		background-image: url("~img/feeling/icon-excited.svg");
	}

	&--energetic:before {
		background-image: url("~img/feeling/icon-energetic.svg");
	}

	&--fearful:before {
		background-image: url("~img/feeling/icon-fearful.svg");
	}

	&--happy:before {
		background-image: url("~img/feeling/icon-happy.svg");
	}

	&--interested:before {
		background-image: url("~img/feeling/icon-interested.svg");
	}

	&--joyful:before {
		background-image: url("~img/feeling/icon-joyful.svg");
	}

	&--love:before {
		background-image: url("~img/feeling/icon-love.svg");
	}

	&--nostalgic:before {
		background-image: url("~img/feeling/icon-nostalgic.svg");
	}

	&--sad:before {
		background-image: url("~img/feeling/icon-sad.svg");
	}

	&--satisfied:before {
		background-image: url("~img/feeling/icon-satisfied.svg");
	}

	&--scared:before {
		background-image: url("~img/feeling/icon-satisfied.svg");
	}

	&--shocked:before {
		background-image: url("~img/feeling/icon-shocked.svg");
	}

	//
	// Rating
	//
	&--rating-worst:before {
		width: 24px;
		height: 24px;
		background-image: url("~img/feeling/icon-angry.svg");
	}
	&--rating-poor:before {
		width: 24px;
		height: 24px;
		background-image: url("~img/feeling/icon-sad.svg");
	}
	&--rating-ok:before {
		width: 24px;
		height: 24px;
		background-image: url("~img/feeling/icon-calm.svg");
	}
	&--rating-good:before {
		width: 24px;
		height: 24px;
		background-image: url("~img/feeling/icon-interested.svg");
	}
	&--rating-excellent:before {
		width: 24px;
		height: 24px;
		background-image: url("~img/feeling/icon-love.svg");
	}
}