@import "scss/global/base/variables";

.LayoutMainProfileDivider {
	width: 100%;
	height: 2px;
	margin: $space-large 0;
	background: $border-gray;
	@media screen and (min-width:$medium-break) {
		margin: $space-smallest 0;
	}
}

.LayoutMainProfileTitle {
	margin-bottom: $space-large;
	color: $gray-xdarkest;
	text-transform: uppercase;
	font-weight: 600;
	font-size: $font-size-medium;
	@media screen and (min-width:$medium-break) {
		margin-bottom: $space-smallest;
	}
}

.LayoutMainProfileItem {
	&__link,
	&__button {
		display: flex;
		align-items: center;
		gap: $space-smaller;
		padding: $space-smallest 0;
	}
	.icon {
		color: $font-gray;
		//color: $font-primary;
		font-size: $font-size-ssmall;
	}
	&__title {
		color: $font-primary;
		font-size: $font-size-msmall;
		font-weight: 600;
	}
}

.LayoutMainProfileSocialItem {
	&__link {
		font-size: 0;
		height: 50px;
		width: 50px;
		border-radius: 50%;
		border: 1px solid $ocean-light;
		display: flex;
		align-items: center;
		justify-content: center;
		.icon {
			color: $ocean-light;
		}
	}
}
