@import "scss/global/base/variables.scss";

.profile-settings__social__item {
  display:flex;
  justify-content: space-between;
  align-items: center;
  height:48px;
  border-bottom:2px solid $border-gray;
  &:not(:last-child) {
    margin-bottom:$space-small;
  }
  &__logo{
    font-size:0;
    width:150px;
    height:40px;
  }
  .icon-chain{
    font-size:14px;
    font-weight:bold;
    &:after{
      font-weight:bold;
      vertical-align:middle;
    }
  }
  .input-toggle{
    flex:1;
    position:relative;
    max-width:600px;
    &:hover{
      .input-toggle__remove{
        display:block;
      }
    }
    &__remove{
      position:absolute;
      right:2px;
      top:50%;
      transform:translatey(-50%);
      display:none;
      color:$blue-medium;
      transition:$transition-std;
      cursor:pointer;
      z-index:10;
      &:hover{
        color:$blue-darker;
      }
    }
    .Mui-error.u-color-problem {
      position: absolute;
      bottom: -20px;
    }
  }
  .facebook{
    background: url("../../../../../img/socials/facebook.png") no-repeat;
  }
  .instagram{
    background: url("../../../../../img/socials/instagram.png") no-repeat;
  }
  .twitter{
    background: url("../../../../../img/socials/twitter.png") no-repeat;
  }
}