@import "../base/variables";

/************************
TYPOGRAPHY
************************/


.u-paragraph{
  font-size:rem-calc(17);
  line-height:28px;
  margin-top:0;
  &--small{
    max-width:500px;
  }
}

@media screen and (min-width:$small-break){
  .u-paragraph{
    &--large{
      max-width:45%;
    }
  }
}

.u-hidden{
  display: none;
}

/*title
***************************/

.u-title-small{
  font-size:19px;
  font-weight:600;
  &.u-title--no-margin{
    + *{
      margin-top:0!important;
    }
  }
  &.u-title--margin-top{
      margin-top:$space-small;
  }
}


.u-title-medium{
  font-weight: 500;
  font-size: rem-calc(21);
}

.u-title-large{
  font-weight:700;
  font-size:rem-calc(28);
  margin-bottom:$space-small;
}




/*javascript classes
***************************/
.is-open{
  z-index:80;
}

.is-disabled{
  opacity:0.6;
  pointer-events: none;
}


/*animate box
***************************/
.u-animate-state{
  transition:$transition-std;
  opacity:0;
  transform:translatey(-10px);
  overflow: hidden;
  pointer-events: none;
  .is-open &, &.is-modal-template-open{
    pointer-events: all;
    transform:translatey(0);
    opacity:1;
    overflow:visible;
  }
}

.u-animate-modal{
  transition:$transition-std;
  opacity:0;
  z-index:99;
  &.is-modal-open{
    opacity:1;
  }
}

.u-link{
  color:$blue-medium;
  transition:$transition-std;
  cursor:pointer;
  &:hover{
    color: $blue-darker;
  }
}

.u-icon-close{
  color:$blue-medium;
  transition:$transition-std;
  cursor:pointer;
  font-size:0;
  &:hover{
    color: $blue-darker;
  }
  &::after{
    font-size:16px;
  }
}

/*color types
*********************************/

.u-color-problem{
  color:$red-medium;
}

.u-color-approved{
  color:$green-medium;
}

.u-color-warning{
  color:$yellow-medium;
}

.u-color-draft{
  color:$font-third;
}


/*tip*/

.u-input-footer{
  display:flex;
  justify-content:space-between;
  color:$font-third;
  margin:$space-smallest 0 0;
  font-size:13px;
  .u-input-help{
    font-style:italic;
  }
  .u-input-counter{
    text-align: right;
  }
}

/*positions*/

.u-right-side{
  display:flex;
  justify-content: flex-end;
}


/*spaces*/

.u-margin-left{
  margin-left:$space-smaller;
}

.main-title{
  margin-bottom:$space-large;
}

.title-plus{
  &__wrapper{
    display:flex;
    align-items: center;
  }
  &__adder{
    margin-left:auto;
  }
}


/*forms
*****************************************/

.u-form-space{
  margin-bottom:$space-medium;
}