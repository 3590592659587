@import "../../../../global/base/variables";

.ely-aside {
  transform:translatex(-110%);
  position:fixed;
  width:100%;
  padding:$space-from-header 0;
  transition:0.4s ease all;
  background:white;
  height:100vh;
  z-index:30;
  .is-aside-open &{
    transform:translatex(0);
  }
}

@media screen and (min-width:$small-break){
  .ely-aside{
    width:300px;
    height:auto;
  }
}

@media screen and (min-width:$medium-break){
  .ely-aside{
    z-index:0;
    position:static;
    transform:translatex(0);
    background:transparent;
  }
}