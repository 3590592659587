@import "scss/global/base/variables";

.LayoutMain {
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: $gray-medium;
	&__main {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: auto;
		padding-bottom: calc(#{$space-xxlarge} + #{$space-xxlarge} + 45px);
		@media screen and (min-width:$medium-break) {
			padding-bottom: $space-medium;
		}
		@media screen and (min-width:$xlarge-break) {
			width: $user-max-width;
			margin: 0 auto;
			padding: 0;
		}

		&--max-width &__inner {
			width:100%;
			margin:0 auto;
			max-width:$user-max-width;
		}
	}
}