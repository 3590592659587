/****************
UTILITIES
*****************/

/*mixins
******************************/
@function rem-calc($size) {
  $remSize: calc($size / 16);
  @return #{$remSize}rem;
}

/*border-radius*/
$border-radius-small: 8px;
$border-radius-medium: 14px;
$border-radius-big: 40px;

/*breakpoints
********************************/
$small-break-int: 600;
$medium-break-int: 960;
$large-break-int: 1426;
$xlarge-break-int: 11540;
$small-break: $small-break-int + 'px';
$medium-break: $medium-break-int + 'px';
$large-break: $large-break-int + 'px';
$xlarge-break: $xlarge-break-int + 'px';

/*general margin large
********************************/
$space-xxxlarge: calc(4.8vw + 10px);
$space-xxlarge: calc(3vw + 10px);
$space-xlarge: calc(2.4vw + 10px);
$space-large: calc(1.8vw + 10px);
$space-medium: calc(1.2vw + 10px);
$space-small: calc(0.6vw + 10px); //used for vertical margin
$space-smaller: 10px;
$space-smallest: 5px;

/********************
General settings
*********************/

/*aside & main width*/
$aside-width: 300px;

/*space-external*/
$space-external: #{$space-medium};

/*space from header*/
$space-from-header: 3vw;
$user-max-width: 1426px;

/*transition*/
$transition-std: all 0.2s cubic-bezier(0, 0, 0.2, 1);

/*box shadow
********************************/
$box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
$box-shadow-intense: 0 1px 3px rgba(0, 0, 0, 0.12),
  0 1px 2px rgba(0, 0, 0, 0.24);

/*********************
COLORS
*********************/

/*red
*********************************/

$red-medium: #e84141;
$red-light: lighten($red-medium, 10%);
$red-darker: darken($red-medium, 10%);

/*purple
*********************************/
$purple-medium: #7540ee;
$purple-light: lighten($purple-medium, 10%);

/*blue
*********************************/
$blue-medium: #4da1ff;
$blue-dark: #1f44aa;
$blue-light: lighten($blue-medium, 10%);
$blue-darker: darken($blue-medium, 10%);

/*ocean
*********************************/
$ocean-dark: #071c8d;
$ocean-medium: #2759ff;
$ocean-light: lighten($ocean-medium, 10%);
$ocean-darker: #143799;
$ocean-darkest: darken($ocean-darker, 10%);

/*water
*********************************/
$water-light: #eaf3ff;

/*pink
*********************************/

$berry-medium: #9d0070;
$berry-light: lighten($berry-medium, 10%);

/*green
*********************************/
$green-medium: #39ad5d;
$green-light: lighten($green-medium, 10%);
$green-darker: darken($green-medium, 10%);

/*teal
*********************************/
$teal-medium: #00b1ce;
$teal-light: lighten($teal-medium, 10%);

/*orangenh*
*********************************/
$orange-medium: #ff7052;
$orange-light: lighten($orange-medium, 10%);

/*gray
*********************************/
$border-gray: #e4eaf3;
$border-gray-hover: #a0a0a0;

$gray-icon: #8b90a0;

$gray-medium: #f9f9f9; //f3f3f3 also
$gray-darker: darken($gray-medium, 5%);
$gray-darkest: #dadada;
$gray-xdarkest: #a1a4b1;

/*black
*********************************/
$black: #000000;

/*white
*********************************/
$white: #ffffff;

/*yellow
*********************************/
$yellow-medium: #efa500;
$yellow-light: lighten($yellow-medium, 10%);

/********************
fonts
*********************/
$font-primary: #333;
$font-primary-hover: darken($font-primary, 10%);

$font-secondary: #4da1ff;

$font-third: #b7bec7;

$font-approved: #39ad5d;
$font-warning: $red-light;

$font-gray: #4a4a4a;
$font-gray-lighter: lighten($font-gray, 10%);

/*********************
font-size
**********************/

$font-size-small: rem-calc(12);
$font-size-ssmall: rem-calc(13);
$font-size-msmall: rem-calc(14);
$font-size-medium: rem-calc(15);
$font-size-smedium: rem-calc(16);
$font-size-lmedium: rem-calc(18);
$font-size-large: rem-calc(21);
$font-size-xlarge: rem-calc(25);
$font-size-xxlarge: rem-calc(28);

$font-size-heading1: calc(1vw + 1.55rem);
$font-size-heading2: calc(1vw + 1.2rem);

/**************************
NodeType icons
 **************************/
$accommodationIcon: "\e90d";
$cruiseIcon: "\e929";
$excursionIcon: "\e917";
$foodIcon: "\e918";
$otherIcon: "\e96e";
$showIcon: "\e933";
$transferIcon: "\e90b";
$poiIcon: "\e968";
$areaIcon: "\e907";
$bundleIcon: "\e91e";

/**************************
Primary Tags
***************************/
$accommodation: #e52121;
$accommodationAvatar: #ea4f4f;
$show: #9d0070;
$showAvatar: #b60082;
$excursion: #189956;
$excursionAvatar: #1baf62;
$food: #7540ee;
$foodAvatar: #8657f0;
$transfer: #fd9a22;
$transferAvatar: #fdb155;
$cruise: #009bb4;
$cruiseAvatar: #00b1ce;
$otherServices: #143799;
$otherServicesAvatar: #1645ca;
$poi: #0078f4;
$poiAvatar: #228dfb;
$location: #0078f4;
$locationAvatar: #228dfb;
$bundle: linear-gradient(
  135deg,
  #009bb5 25%,
  #9d0070 25% 50%,
  #e52121 50% 75%,
  #fd9a22 75% 100%
);
$bundleAvatar: rgba(250, 250, 250, 0.3);

/* Placeholders
***************************/
%std-box {
  border: 1px solid $border-gray;
  padding: $space-large;
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow;
  transition: $transition-std;
  &:hover {
    box-shadow: $box-shadow-intense;
  }
}

/* Wizard Sections
****************************/
$wizard-section-main-width: 80%;
$wizard-section-row-classic: 45%;
$wizard-section-row-large: 66%;
$wizard-section-row-small: 260px;
