@import "scss/global/base/variables.scss";

.JoinBusinessForm {
	display: flex;
	flex-direction: column;
	gap: $space-small;
	padding: $space-external;
	position: relative;
	@media screen and (min-width:$medium-break){
		width: 400px;
		height: 100%;
		overflow-y: auto;
	}
	@media screen and (min-width:$xlarge-break) {
	}

	&__title {
		font-weight: 600;
		font-size: 1.75rem;
		color:$font-primary;
	}
	&__accept,
	&__captcha {
		font-size:14px;
	}
	&__accept {
		label {
			margin-left:$space-smallest;
		}
		input {
			vertical-align: middle;
		}
	}
	&__submit {
		position: relative;
		&__error {
			color: $red-light;
			font-size: $font-size-ssmall;
			position: absolute;
			top: -22px;
		}
	}

	&__social {
		&__text {
			display:block;
			margin:$space-small 0;
			text-align: center;
		}
		&__wrapper {
			display:flex;
			justify-content: center;
		}
		&__item {
			font-size:0;
			button {
				width:60px;
				height:60px;
				border-radius:50%;
				display:flex;
				justify-content: center;
				align-items: center;
				margin:0 $space-small;
				font-size:0;
				cursor:pointer;
				&:before {
					font-family: 'experly-icons';
					-moz-osx-font-smoothing: grayscale;
					-webkit-font-smoothing: antialiased;
					font-style: normal;
					font-variant: normal;
					font-weight: normal;
					line-height: 1;
					speak: none;
					text-transform: none;
					color:white;
					font-size:24px;
				}
			}
		}
		.facebook-login-icon button {
			background-color:#3B5998;
			&:before{
				content:"\e93b";
			}
		}
		.twitter-login-icon button {
			background-color:#55ACEE;
			&:before{
				content:"\e93a";
			}
		}
		.google-login-icon button {
			background-image:url("/img/socials/google+.svg");
			background-color:#e7e7e7;
			background-repeat:no-repeat;
			background-position:center center;
		}
	}

	&__login {
		span {
			display: block;
			margin: calc(0.6vw + 10px) 0;
			text-align: center;
			a {
				color:#4da1ff;
			}
		}
	}
}
