@import "scss/global/base/variables";

.timeline-item-content {
	&__shadow {
		border-radius: 5px;
		box-shadow: $box-shadow;
		transition: $transition-std;
		&:hover{
			box-shadow: $box-shadow-intense;
		}
	}

	&__review {
		&__node {
			display: block;
			border-bottom: 2px solid rgba(0, 0, 0, 0.05);
		}
		&__comment {
			margin: 0;
			padding: $space-small;
			text-align: center;
		}
	}
}