@import "scss/global/base/variables";

.ElyDateRangePickerShop {
	display: flex;
	align-items: center;
	gap: $space-smallest;
	&__icon {
		display: flex;
		align-items: center;
		.icon-date {
			color: lighten($blue-medium, 10%);
		}
	}
	&__field {
		display: flex;
		align-items: center;
		.input {
			display: none;
		}
	}
	&__value {
		display: block;
		background: none;
		border: none;
		color: white;
		font-size: $font-size-small;
	}
	&__clear {
		display: flex;
		align-items: center;
		.icon:after {
			color: lighten($blue-medium, 10%);
		}
	}
}