@import "scss/global/base/variables.scss";

.profile-settings__basic {
	&__container {
	}
	&__photo {
		margin-bottom:$space-medium;
		display:flex;
		align-items:center;
		&__img {
			margin-right:$space-large;
			width:105px;
			height:105px;
			object-fit:cover;
			border-radius: 44px;
		}
		&__actions {
			display: flex;
			flex-wrap: wrap;
			gap: $space-small;
		}
		&__new {
			border:1px solid transparent;
		}
	}
	&__rest {
		flex:1;
		display:grid;
		grid-template-columns: 1fr 1fr;
		grid-gap:$space-small $space-medium;
		&__bio {
			grid-column:1/3;
		}
	}
	&__buttons {
		margin-top:$space-medium;
		text-align:right;
	}
}