@import "scss/global/base/variables";

.ely-modal{
	display:flex;
	justify-content:center;
	align-items:center;
	position:fixed;
	top:0; bottom:0;
	left:0; right:0;
	//background: rgba(100,100,100,0.85);
	background: rgba(0, 0, 0, 0.55);
	z-index:90;
	&__content{
		background:white;
	}
}