@import "scss/global/base/variables.scss";

.teasers-list {
	background: $white;
	box-shadow: $box-shadow;
	border-radius: $border-radius-small*2.5;
	padding: $space-smaller*2.5;
	&:not(:last-child) {
		margin-bottom: $space-medium;
	}

	.teasers-list__title {
		display: flex;
		align-items: center;
		margin-bottom: $space-small;
		font-size: $font-size-medium;
		color: $black;

		.teasers-list__count {
			margin-left: $space-smallest;
			font-size: $font-size-ssmall;
			font-weight: 300;
			color: $font-gray;
		}
	}

	.teasers-list__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	img {
		border-radius: calc(#{$border-radius-big} / 2);
		margin: 0 auto;
		width: 75px;
		height: 75px;
	}

	a.view-all {
		display: flex;
		justify-content: center;
		font-size: $font-size-small;
		font-weight:bold;
		color: $blue-medium;
		margin-top: $space-small;

		&:after {
			line-height: 0.8;
		}
	}
}