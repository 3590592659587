@import "scss/global/base/variables";

.HeaderButton {
	width: 46px;
	height: 46px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: white;
	border-radius: 50px;
	outline: 0;
	-webkit-tap-highlight-color: transparent;
	.icon:before,
	.icon:after {
		color: $poi;
		font-size: 19px;
	}
	&--small {
		width: 36px;
		height: 36px;
		.icon:before {
			font-size: 16px;
		}
	}
}