@import "scss/global/base/variables";

.profile-settings__interests {
    &__container {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -$space-smallest;
    }

    &__item {
        margin-bottom: $space-smallest;
        &:not(:last-child) {
            margin-right: $space-smaller;
        }
    }

    &__edit {
        text-transform: initial;
        padding: $space-smaller $space-small;
        font-size: $font-size-small;
        font-weight: 400;
        color: $white;
        background: #0078F3;
        border-radius: $border-radius-big;
        .icon {
            margin-right: $space-smaller;
            color: $white;
            font-size: $font-size-ssmall;
        }
    }

    &__buttons {
        margin-top: $space-medium;
        text-align: right;
    }
}