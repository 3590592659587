@import "scss/global/base/variables";

.change-password {
  .ely-password {
    margin-bottom:$space-small;
  }
  &__buttons {
    display:flex;
    margin-top:$space-medium;
    &__submit {
      margin-left:auto;
      flex-basis:48%;
    }
  }
}