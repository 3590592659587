@import "scss/global/base/variables";

.AuthModal {
	&__content {
		width: 100%;
		height: 100%;
		@media screen and (min-width:$medium-break) {
			height: auto;
			width: fit-content;
			border-radius: $border-radius-big;
			overflow: hidden;
		}
	}
}