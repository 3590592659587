@import "scss/global/base/variables";

.LayoutMainMenu {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.menu-link {
		width: 30px;
		height: 30px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		&--logo {
			&.active .icon {
				background: linear-gradient(
					135deg,
					rgba(0, 177, 206, 1) 0%,
					rgba(0, 177, 206, 1) 35%,
					rgba(157, 0, 112, 1) 35%,
					rgba(157, 0, 112, 1) 50%,
					rgba(232, 56, 56, 1) 50%,
					rgba(232, 56, 56, 1) 65%,
					rgba(255, 180, 0, 1) 65%,
					rgba(255, 180, 0, 1) 100%
				);
				background-clip: text;
				color: transparent;
			}
		}
		&--search {
			&.active .icon {
				background: linear-gradient(
					135deg,
					rgba(0, 177, 206, 1) 0%,
					rgba(157, 0, 112, 1) 47%,
					rgba(232, 57, 55, 1) 65%,
					rgba(255, 180, 0, 1) 100%
				);
				background-clip: text;
				color: transparent;
				&::before {
					color: transparent;
				}
			}
		}
		&--shop {
			&.active .icon {
				background: linear-gradient(
					135deg,
					rgba(0, 177, 206, 1) 0%,
					rgba(157, 0, 112, 1) 47%,
					rgba(232, 57, 55, 1) 65%,
					rgba(255, 180, 0, 1) 100%
				);
				background-clip: text;
				color: transparent;
				&::before {
					color: transparent;
				}
			}
		}
		&--profile {
			width: 32px;
			height: 32px;
			padding: 2px;
			border-radius: 50%;
			&.active {
				background: linear-gradient(
					135deg,
					rgba(0, 177, 206, 1) 0%,
					rgba(0, 177, 206, 1) 35%,
					rgba(157, 0, 112, 1) 35%,
					rgba(157, 0, 112, 1) 50%,
					rgba(232, 56, 56, 1) 50%,
					rgba(232, 56, 56, 1) 65%,
					rgba(255, 180, 0, 1) 65%,
					rgba(255, 180, 0, 1) 100%
				);
			}
			img {
				width: 30px;
				height: 30px;
				max-width: initial;
				background: white;
			}
		}
	}
	.menu-icon {
		font-size: 28px;
		color: $font-third;
	}
	.add-outer {
		padding: 7px;
		margin: 0 -7px;
		background: white;
		border-radius: 50%;
		position: relative;
		z-index: 90;
		@media screen and (max-width:$medium-break) {
			&:before {
				content: '';
				width: 72px;
				height: 72px;
				border-radius: 50%;
				position: absolute;
				top: 0;
				left: 0;
				box-shadow: $box-shadow;
				z-index: 10;
			}
			&:after {
				content: '';
				width: 78px;
				height: 72px;
				background: white;
				position: absolute;
				top: 20px;
				left: -3px;
				z-index: 20;
			}
		}
	}
	.add {
		height: auto;
		padding: 20px;
		color: white;
		background: $ocean-dark;
		border-radius: 50%;
		position: relative;
		z-index: 30;
		&:before {
			font-size: 18px;
		}
		&-link {
			display: inline-flex;
		}
	}
	.user {
		border-radius: 50%;
	}

	&--desktop {
		display: none;
		gap: $space-medium;
		.add-outer {
			margin-bottom: -15px;
		}
		@media screen and (min-width:$medium-break) {
			display: flex;
		}
	}

	&--mobile {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		background: white;
		padding: $space-xlarge $space-external;
		box-shadow: $box-shadow;
		border-top-left-radius: $border-radius-big;
		border-top-right-radius: $border-radius-big;
		z-index: 90;
		@media screen and (min-width:$medium-break) {
			display: none;
		}

		.add-outer {
			margin-top: -40px;
		}
	}

	&__profile {
		display: inline-flex;
		position: relative;
		.profile-menu {
			width: 350px;
			max-height: 85vh;
			padding: $space-smaller;
			overflow: auto;
			position: absolute;
			top: calc(100% + 24px);
			z-index: 99;
			box-shadow: $box-shadow;
			border-radius: $border-radius-medium;
		}
	}
}