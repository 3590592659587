@import "scss/global/base/variables.scss";

.profile-friends {
	width: 100%;
	display: grid;
	grid-gap: $space-smaller;
	grid-template-columns: repeat(auto-fit, minmax(rem-calc(79), 1fr));
	@media screen and (min-width:$medium-break){
		grid-gap: $space-smaller;
	}
	@media screen and (min-width:$large-break){
		grid-gap: $space-small;
	}

	&__item {
		display: flex;
		flex-direction: column;

		&__name {
			text-align: center;
			color: $black;
			font-weight: 300;
			font-size: $font-size-msmall;
			margin-top: $space-smallest;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
}
