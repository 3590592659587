@import "scss/global/base/variables";

.profile-tab-friends {
	&__header {
		padding: $space-medium 0;
		display: flex;
		justify-content: space-between;
		.friends-title {
			display: flex;
			align-items: center;
			font-size: $font-size-large;
		}
		.friends-count {
			margin-left: $space-smallest;
			font-size: $font-size-small;
			font-weight: normal;
		}
		.friends-add {
			&__icon {
				color: $blue-medium;
				&:before {
					font-size: $font-size-small;
				}
			}
			&:hover {
				.friends-add__icon {
					color: $white;
				}
			}
		}
	}
	&__empty {
		height: 20vh;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
	&__list {
		&__actions {
			margin-top: $space-medium;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}