@import "scss/global/base/variables";

.ely-tag {
	height: 32px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	background-color: #e0e0e0;
	border-radius: 16px;

	&__avatar {
		width: 26px;
		height: 26px;
		margin-left: 3px;
		margin-right: -6px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $ocean-dark;
		font-size: rem-calc(16);
		line-height: 1;
		background: #bdbdbd;
		border-radius: 50%;
	}

	&__emote:before {
		width: 24px;
		height: 24px;
	}

	&__label {
		overflow: hidden;
		white-space: nowrap;
		padding-left: 12px;
		padding-right: 12px;
		color: $font-primary;
		font-size: $font-size-small;
		font-weight: 600;
		line-height: 1.2;
		text-overflow: ellipsis;
	}

	//
	// Amenity Variant
	//
	&--amenity {
		background: transparent;
		.ely-tag__avatar {
			margin-left: 0;
			background: transparent;
		}
	}

	&--tag {
		background: $font-secondary;
		.ely-tag__label {
			color: $white;
		}
	}

	&--tag-secondary {
		background-color: #F1F7FF;
		.ely-tag__label {
			color: $font-secondary;
			font-weight: 500;
		}
	}

	//
	// Emote Variant
	//
	&--emote {
		justify-content: space-between;
		padding-left: $space-smallest;
		background: $font-secondary;
		.ely-tag__label {
			color: $white;
		}
	}

	//
	// Primary variant
	//
	&--primary {
		.ely-tag__avatar {
			color: $white;
		}
		.ely-tag__label {
			color: $white;
		}

		&.food_and_beverage {
			background: $food;
			.ely-tag__avatar {
				background: $foodAvatar;
			}
		}
		&.accommodation {
			background: $accommodation;
			.ely-tag__avatar {
				background: $accommodationAvatar;
			}
		}
		&.show_and_event {
			background: $show;
			.ely-tag__avatar {
				background: $showAvatar;
			}
		}
		&.excursion {
			background: $excursion;
			.ely-tag__avatar {
				background: $excursionAvatar;
			}
		}
		&.transfer {
			background: $transfer;
			.ely-tag__avatar {
				background: $transferAvatar;
			}
		}
		&.cruise {
			background: $cruise;
			.ely-tag__avatar {
				background: $cruiseAvatar;
			}
		}
		&.other {
			background: $otherServices;
			.ely-tag__avatar {
				background: $otherServicesAvatar;
			}
		}
		&.poi {
			background: $poi;
			.ely-tag__avatar {
				background: $poiAvatar;
			}
		}
		&.area {
			background: $location;
			.ely-tag__avatar {
				background: $locationAvatar;
			}
		}
		&.bundle {
			background: $bundle;
			.ely-tag__avatar {
				background: $bundleAvatar;
			}
		}
	}
}