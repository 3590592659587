@import "scss/global/base/variables";

.public-settings {
	padding: 0 $space-medium;
}

@media screen and (min-width:$medium-break){
	.profile-people {
		padding: 0;
		margin-top:$space-external;
		margin-bottom:$space-external;
		display: grid;
		grid-template-columns: 310px 1fr;
		grid-gap: 3rem;
		&__aside {
			padding:0;
		}
	}
}