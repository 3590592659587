.generic-not-found {
  flex: 1;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Arial,sans-serif;
  font-size: 1.3em;
  color: #000;
  text-shadow: #ddd 2px 2px 2px;

  .go-back {
    color: #4da1ff;
    &:hover {
      color: #1a86ff;
    }
  }
}