@import "../../global/base/variables";

/*NOTIFICATIONS CLASSES*/


.pending-notification{
  font-size:13px;
  &__pending {
    color:$font-third;
    &:before {
      margin-right: $space-smallest;
      position: relative;
      top: 2px;
    }
  }
  &__success{
    color:$green-medium;
  }
}




/*******************
NOTIFICATION BOX DASHBOARD
********************/

.notification{
  display:flex;
  justify-content: center;
  margin-bottom:$space-small;
  &__box{
    background-color:$water-light;
    padding:$space-small;
    border:1px solid $border-gray;
    box-shadow:$box-shadow;
    transition:$transition-std;
    border-radius: $border-radius-small;
    a{
      margin-left:$space-smaller;
      color:$font-secondary;
    }
    &:hover{
      box-shadow:$box-shadow-intense;
    }
  }
}


/***************************
GENERAL POPUP NOTIFICATION
***************************/

.u-notification {
  background:white;
  box-shadow: 0 2px 8px 3px rgba(0, 0, 0, 0.15);
  &__header {
    display: flex;
    padding: 16px calc(0.6vw + 10px);
    justify-content: space-between;
    color: #333;
    border-bottom: 1px solid #dadada;
  }
  &__close {
    padding: 0;
    font-size: 0;
    cursor: pointer;
    &:hover {
      color: #4da1ff;
    }
    &:after {
      font-size: 25px;
    }
  }
  &__body {
    padding: 6px calc(0.6vw + 10px);
    ul * {
      padding: 10px 0;
      color: $font-secondary;
    }
  }
  &.no-title {
    display: flex;
    justify-content: space-between;
    .u-notification__close {
      padding: 6px calc(0.6vw + 10px);
    }
  }
}

