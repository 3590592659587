@import "scss/global/base/variables";

.profile-tab-friends-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: $space-small;
	border-top: 2px solid $gray-medium;
	&:last-child {
		border-bottom: 2px solid $gray-medium;
	}
	&__left {
		display: flex;
		.friend-photo {
			display: block;
			position: relative;
			&__img {
				width: 74px;
				height: 74px;
				display: block;
				object-fit: cover;
				border-radius: 22px;
				@media screen and (min-width: $small-break) {
					width: 100px;
					height: 100px;
					border-radius: 30px;
				}
				@media screen and (min-width: $medium-break) {
					width: 120px;
					height: 120px;
					border-radius: 40px;
				}
			}
			&__emote {
				position: absolute;
				right: 0;
				bottom: 0;
			}
		}
		.friend-info {
			margin-left: $space-small;
			display: flex;
			gap: $space-smallest;
			flex-direction: column;
			justify-content: space-around;
			&__name {
				display: block;
				font-size: $font-size-lmedium;
				font-weight: 600;
			}
			&__status {
				color: $font-third;
				font-size: $font-size-medium;
			}
		}
	}
	&__actions {
		display: flex;
		flex-direction: column;
		&__ignore {
			margin-top: $space-smallest;
		}
	}
	&.small {
		padding: 0;
		.friend-photo {
			&__img {
				width: 40px;
				height: 40px;
				border-radius: 12px;
				@media screen and (min-width: $small-break) {
					width: 74px;
					height: 74px;
					border-radius: 22px
				}
			}
			&__emote {
				width: 20px;
				height: 20px;
				position: absolute;
				right: -2px;
				bottom: -2px;
			}
		}
		.friend-info {
			&__name {
				display: block;
				font-size: $font-size-medium;
			}
			&__status {
				font-size: $font-size-ssmall;
			}
		}
		.action-btn {
			padding: $space-smallest $space-smaller;
			font-size: $font-size-small;
		}
	}
}