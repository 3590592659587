@import "../../global/base/variables";

$padding-button:14px 25px;

.nav-link {
  display:block;
  font-weight:bold;
  padding: 10px $space-external;
  margin-bottom:15px;
  font-size:rem-calc(13);
  border-left:6px solid transparent;
  text-transform: uppercase;
  transition:all 0.2s linear;
  &--primary {
    color: $blue-medium;
    &.active{
      border-left:6px solid $blue-medium;
      color:$font-primary;
    }
    &:hover{
      color:$blue-darker;
    }
  }
}