@import "scss/global/base/variables";

.profile-user {
	//background: $gray-medium;
	margin-bottom: $space-medium;

	@media screen and (min-width:$medium-break) {
		padding: $space-small;
		background: $white;
		border-radius: $border-radius-medium;
		box-shadow: $box-shadow;
	}

	.profile-user__info {
		display: flex;
		&:not(:last-child) {
			margin-bottom: $space-medium;
		}

		.profile-user__image {
			width:120px;
			height:120px;
			position: relative;

			img {
				width:100%;
				height:100%;
				object-fit:cover;
				border-radius: $border-radius-big;
				box-shadow: 0 5px 5px lighten( $border-gray-hover, 20% );
			}

			.profile-user__emote {
				position: absolute;
				right: 0;
				bottom: 0;
			}
		}

		.profile-user__basic {
			flex: 1;
			margin-left: $space-small;
			display: flex;
			flex-direction: column;
			gap: $space-smaller;

			&__name {
				font-size: $font-size-large;
			}

			.login-to-connect {
				padding: 14px 20px;
			}

			.profile-user__basic__location {
				font-size: $font-size-small;
				font-weight: 300;
			}

			.profile-user__basic__points {
				display: flex;
				align-items: center;
				font-size: $font-size-ssmall;
				font-weight: 600;

				&:before {
					content:" ";
					background: url("~img/points-icon.svg") no-repeat;
					background-size: cover;
					margin-right: $space-smallest;
					width: 19px;
					height: 19px;
				}
			}

			button.accept {
				margin-right: $space-smaller;
			}

			a.profile-user__basic__edit {
				font-size: $font-size-small;
				font-weight:bold;
				color: $blue-medium;

				&:before {
					font-size: calc(10px);
					margin-right: $space-smallest;
				}
			}
		}
	}

	&__status-update--mobile {
		@media screen and (min-width:$medium-break){
			display: none;
		}
	}

	h3 {
		font-size: $font-size-ssmall;
		margin-bottom: $space-smallest;
	}

	.profile-user__bio {
		.profile-user__bio_text {
			line-height: 1.5;
			font-size: $font-size-msmall;
			margin-top: 0;
		}
	}

	// Social

	.profile-user__social {
		display: flex;
		color: red;
		&:not(:last-child) {
			margin-bottom: $space-medium;
		}

		a {
			padding: $space-smaller;
			margin-right: $space-smallest;
			background: red;
			color: $white;
			font-size: 0;
			border-radius: $border-radius-big;

			&.icon-facebook {
				background: #1877F1;
			}

			&.icon-instagram {
				background: #B60082;
			}

			&.icon-twitter {
				background: #55ACEE;
			}

			&:before {
				font-size: $font-size-msmall;
			}
		}
	}
	

	.profile-user__interests {
		font-size: calc(11px);
		text-transform: uppercase;
		color: darken($gray-darkest, 68%);

		&__list {
			display: flex;
			flex-wrap: wrap;
		}

		&__item {
			margin-top: $space-smallest;
			margin-right: $space-smaller;
		}
	}
}
