@import "scss/global/base/variables";

.LayoutMainProfileMenu {
	display: flex;
	flex-direction: column;
	gap: $space-smallest;
	background: $gray-medium;

	.login,
	.view-profile {
		width: 100%;
	}
	.for-business,
	.join-business {
		text-align: left;
		font-size: $font-size-msmall;
	}
	.view-profile {
		margin-bottom: $space-large;
		@media screen and (min-width:$medium-break) {
			margin-bottom: $space-small;
		}
	}

	&__register {
		margin-top: $space-small;
		.text {
			font-size: $font-size-medium;
		}
		.ely-link {
			font-size: $font-size-medium;
		}
	}

	&__user {
		margin-bottom: $space-large;
		display: flex;
		align-items: center;
		gap: $space-large;
		@media screen and (min-width:$medium-break) {
			margin-bottom: $space-smaller;
			gap: $space-smaller;
		}
		.user-image {
			width: 80px;
			height: 80px;
			border-radius: 50px;
		}
		.user-details {
			display: flex;
			flex-direction: column;
			gap: $space-medium;
			@media screen and (min-width:$medium-break) {
				gap: $space-smaller;
			}
			.name {
				font-size: $font-size-lmedium;
				font-weight: bold;
			}
			.points {
				display: flex;
				align-items: center;
				font-size: $font-size-ssmall;
				font-weight: 600;

				&:before {
					content:" ";
					background: url("~img/points-icon.svg") no-repeat;
					background-size: cover;
					margin-right: $space-smallest;
					width: 19px;
					height: 19px;
				}
			}
		}
	}

	&__socials {
		display: flex;
		gap: $space-small;
	}

	&__copyright {
		margin-top: $space-large;
		@media screen and (min-width:$medium-break) {
			margin-top: $space-smaller;
		}
		color: $gray-xdarkest;
		font-size: $font-size-ssmall;
		font-weight: 600;
		letter-spacing: 2px;
	}
}