@import "scss/global/base/variables";

.profile {

	&__header {
		background: url('~img/user-profile-cover.svg') no-repeat center center;
		background-size: cover;
		&__inner {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: $space-external $space-external calc(#{$space-external} + 50px);
			position: relative;
			@media screen and (min-width:$large-break) {
				width: $user-max-width;
				margin: 0 auto;
				padding: $space-external 0 calc(#{$space-external} + 50px);
			}

			.logo {
				display: inline-flex;
				@media screen and (min-width: $medium-break) {
					display: none;
				}
				.img {
					height: 30px;
				}
			}
		}
	}

	&__settings {
		display: flex;
		justify-content: center;
		position: absolute;
		top: 0;
		bottom: 50px;
		right: 0;
		.settings-btn {
			padding: $space-external;
			@media screen and (min-width:$large-break) {
				padding: 0;
				margin: auto;
			}
		}
		.icon-settings {
			color: white;
		}

		.modal {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(100,100,100,0.75);
			display: flex;
			align-items: flex-end;
			justify-content: stretch;
			z-index: 99;
			&__content {
				width: 100%;
				padding: $space-xxxlarge $space-external;
				background: $white;
				border-top-left-radius: $border-radius-big;
				border-top-right-radius: $border-radius-big;
				color: $font-primary;
				font-size: $font-size-msmall;
				.nav {
					display: flex;
					flex-direction: column;
					gap: $space-medium;
				}
				.ProfileSettingsItem {
					&__link {
						display: flex;
						align-items: center;
						gap: $space-small;
						padding: $space-smaller 0;
					}
					.icon {
						color: $font-gray;
						font-size: $font-size-ssmall;
					}
					&__title {
						color: $font-primary;
						font-size: $font-size-msmall;
						font-weight: 600;
					}
				}
				.settings-divider {
					margin: $space-medium 0;
					border-top: 2px solid #ececec;
				}
			}
		}

		.u-animate-modal{
			transition:$transition-std;
			opacity:0;
			z-index:90;
			&.is-modal-open{
				opacity:1;
			}
		}
	}

	&__main {
		@media screen and (min-width:$medium-break){
			margin: 0 auto $space-large auto;
			max-width: $user-max-width;
			display: flex;
		}
	}

	&__left {
		width: 100%;
		margin-top: -50px;
		padding: $space-external;
		position: relative;
		background: $gray-medium;
		border-radius: $border-radius-medium;

		@media screen and (min-width:$medium-break){
			width: 376px;
			padding: 0;
			background: inherit;
		}
	}

	&__right {
		width: 100%;
		padding: $space-external;
		@media screen and (min-width:$medium-break) {
			padding: 0;
		}

		.profile__new-card,
		.profile__non-friend-card {
			padding:$space-xxlarge;
			background:#FFFFFF;
			box-shadow:$box-shadow;
			border-radius:5px;
			text-align:center;

			.accept {
				margin-right: $space-smaller;
			}
		}

		.profile__status-update--desktop {
			display: none;
			@media screen and (min-width:$medium-break){
				display: block;
			}
		}

		@media screen and (min-width:$medium-break){
			width: 75%;
			margin: $space-medium 0 0 $space-medium;
		}
	}
}