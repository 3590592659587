$icomoon-font-path: "~/img/fonts";

/*General
************************/

html {
  line-height: 1.15;
  box-sizing: border-box;
}

img{
  max-width:100%;
}

body {
  margin: 0;
  font-family: 'Poppins', system-ui, sans-serif;
  color: $font-primary;
	&.modal-open {
		overflow: hidden;
	}
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/*more specific
*****************************/

h1, h2, h3, h4, h5, h6, a {
  margin:0;
}

h1{
  font-size:calc(1vw + 1.55rem);
  font-weight:600;
  line-height:1.3;
}

h2{
  font-size:calc(1vw + 1.2rem);;
  font-weight:600;
  line-height:1.3;
}

a{
  text-decoration: none;
  color:inherit;
}

ul, li {
  margin:0;
  padding:0;
  list-style: none;
}

a, button {
  outline: 0;
}

button{
  margin: 0;
  padding: 0;
  background:transparent;
  border:none;
}

/*specific elements
*****************************/
option{
  border:none;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  padding: 0;
}
