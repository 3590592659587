@import "scss/global/base/variables.scss";

.profile-status {
	display: flex;
	align-items: center;
	gap: $space-smaller;

	.profile-status-feeling,
	.profile-status-location {
		font-size: $font-size-small;
		font-weight: 600;
		border-radius: $border-radius-big;
	}

	.profile-status-feeling {
		background: $ocean-darker;
		color: $white;
		padding: $space-smaller*1.2 $space-medium;
		display: flex;
		align-items: center;
		gap: $space-small;
		@media screen and (min-width:$medium-break){
			padding: $space-smaller*1.2 $space-smaller $space-smaller*1.2 $space-small;
			gap: $space-smallest;
		}

		&__emote {
			margin-left: $space-smaller*1.5;
		}

		.icon:before {
			color: $font-secondary;
			font-size: 16px;
		}
	}
		
	.profile-status-location {
		padding: $space-small;
		background: lighten($border-gray, 8%);
		color: lighten($black, 45%);

		.icon-pin {
			margin-left: $space-smaller;
			

			&:before {
				font-size: $font-size-msmall;
				margin-right: $space-smallest;
			}
		}
	}
}