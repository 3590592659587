@import "scss/global/base/variables";

$padding-button:14px 25px;

.ely-link {

	&--link {
		&--primary {
			color:$font-secondary;
			&:hover {
				color:#1a86ff;
			}
		}
	}

	&--button {
		font-weight:bold;
		font-size:rem-calc(14);
		transition:$transition-std;
		border-radius: $border-radius-small;
		text-align: center;
		padding:$padding-button;
		cursor:pointer;
		display:inline-flex;
		justify-content: center;
		align-items:center;
		white-space: nowrap;
		&--primary {
			color:white;
			background-color:$blue-medium;
			&:hover{
				background-color: $blue-darker;
			}
		}
		&--secondary {
			color: $blue-medium;
			background-color: $gray-medium;
			&:hover{
				background-color: $gray-darker;
			}
		}
		&--hollow {
			color: $blue-medium;
			background-color: transparent;
			border: 1px solid $blue-medium;
			&:hover {
				color: white;
				background-color: $blue-medium;
				border-color: transparent;
			}
		}
		&--text {
			font-weight: 400;
			color: $blue-medium;
			background-color: transparent;
			&:hover {
				background-color: rgba(77, 161, 255, 0.04);
			}
		}
	}
}