@import "scss/global/base/variables.scss";

.ForgotPassword {
	display: flex;
	flex-direction: column;
	gap: $space_large;
	@media screen and (min-width:$medium-break) {
		gap: $space_small;
	}

	&__error {
		color: $red-light;
		font-size: $font-size-msmall;
	}

	&__submit {
		margin-top:$space-medium;
		width: 100%;
	}
}