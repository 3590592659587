#ely-modal-template{
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  width:100%;
  z-index:99;

  .modal-template {
    display:flex;
    height:100vh;
    flex-direction:column;
  }
}