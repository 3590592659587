@import "scss/global/base/variables";

.timeline-item {

	.timeline-item-title {
		color: $black;
		display: inline-block;
		width: 100%;
		position: relative;
		font-size: $font-size-medium;
		font-weight: 600;
		margin: $space-large 0 0 0;

		&:after {
			position: absolute;
			border-radius: 100%;
			top: -1rem;
			left: -4.1rem;
			content: ' ';
			background: $border-gray;
			height: 3.1rem;
			width: 3.1rem;
		}

		&:before {
			color: $white;
			position: absolute;
			top: -0.15rem;
			left: -3.35rem;
			font-size: 1.6rem;
			z-index: +1;
		}

		&__user {
			margin-left: $space-smallest;
		}
	}

	.timeline-item-content {
		margin-top: $space-small
	}

	.timeline-item-points {
		display: inline-block;
		background: $ocean-darker;
		color: $white;
		padding: $space-smallest $space-smaller;
		margin-top: $space-small;
		font-size: $font-size-small;
		font-weight: bold;
		border-radius: $border-radius-big;

		&:after {
			display: inline-block;
			content: " ";
			background: url("~img/points-icon.svg") no-repeat;
			background-size: cover;
			margin-left: $space-smallest;
			width: 13px;
			height: 13px;
		}
	}


	/* Title Icons
	**********************************/

	.icon-create-profile {
		&:before {
			content: "\e966";
			left: -3.25rem;
			font-size: 1.4rem;
		}
	}

	.icon-edit-profile {
		&:before {
			content: "\e964";
			left: -3.25rem;
			font-size: 1.4rem;
		}
		&:after {
			background: #BB30E1;
		}
	}

	.icon-authentication-success {
		&:before {
			content: "\e904";
		}
	}

	.icon-feel-update {
		&:before {
			content: "\e963";
		}
		&:after {
			background: $ocean-darker;
		}
	}

	.icon-add-friend,
	.icon-accept-friend {
		&:before {
			content: "\e967";
		}
		&:after {
			background: #FA9D44;
		}
	}

	.icon-poi-create,
	.icon-poi-create-revision,
	.icon-add-review {
		&:before {
			content: "\e965";
		}
		&:after {
			background: #56c6d5;
		}
	}
}

