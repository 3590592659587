@import "scss/global/base/variables";

.profile-feeling {
	margin-bottom: $space-medium;
	padding: $space-smaller $space-smaller $space-smaller $space-medium;
	border: 2px dashed #EBEBEB;
	border-radius: $border-radius-big;
	position: relative;
	&__form {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__status {
		width: 100%;
		margin: 0;
		padding: 0;
		color: $font-primary;
		font-size: 18px;
		line-height: 1;
		border: none;
		background: transparent;
	}
	&__actions {
		display: flex;
		align-items: center;
		&__submit {
			padding: 9px 12px;
			margin-right: $space-smaller;
			color: white;
			font-size: $font-size-ssmall;
			background: $blue-medium;
			border-radius: 30px;
			box-shadow: $box-shadow;
		}
		&__mood {
			display: flex;
			align-items: center;
			padding: 6px 8px;
			font-size: $font-size-ssmall;
			background: $white;
			border-radius: 30px;
			box-shadow: $box-shadow;
			&__emote:before {
				width: 20px;
				height: 20px;
				margin-right: $space-smallest;
			}
		}
	}
	&__emotes-select {
		max-width: 490px;
		padding: $space-medium;
		columns: 2;
		column-gap: $space-large;
		background: $white;
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 30px;
		box-shadow: $box-shadow;
		z-index: 99;
		&__item {
			margin: 0 0 $space-medium 0;
			padding: 0;
			display: flex;
			align-items: center;
			font-size: $font-size-medium;
			color: $black;
		}
		&__icon {
			
		}

		.ely-emote {
			margin-right: $space-smaller;
		}
	}
}