@import "scss/global/base/variables.scss";

.profile-settings {
  &__buttons {
    display:flex;
    margin-top:$space-medium;
    &__submit {
      margin-left:auto;
      flex-basis:48%;
    }
  }
}