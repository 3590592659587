@import "scss/global/base/variables";

.LayoutMainHeader {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: $space-smaller $space-external;
	background: $white;
	&__logo {
		display: inline-flex;
		.img {
			height: 30px;
		}
	}

	@media screen and (min-width:$medium-break) {
		padding: 0 $space-external;
		position: relative;
		z-index: 99;
		&__logo {
			position: absolute;
			left: $space-external;
			.img {
				height: 50px;
			}
		}
	}
}