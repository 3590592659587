@import "scss/global/base/variables";

.profile-achievements__item {
	text-align: center;

	// ICONS

	.profile-achievements__item__icon {
		display: block;
		margin: 0 auto;
		width: 72px;
		height: 70px;
	
		&.newbie {
			background: url("~img/achievements/icon-newbie.png") no-repeat;
		}

		&.local-insider {
			background: url("~img/achievements/icon-local-insider.png") no-repeat;
		}

		&.adventurer {
			background: url("~img/achievements/icon-adventurer.png") no-repeat;
		}
	}

	.profile-achievements__item__name {
		font-size: $font-size-small;
		color: $font-gray;
		margin-top: $space-smaller;
	}

}