@import "scss/global/base/variables";

.HeaderButtonClose {
	&--secondary {
		background: #FAFAFC;
		border: 1px solid #E7EDF5;
	}
	.icon:after {
		font-size: 24px;
	}
}