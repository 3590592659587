@import "scss/global/base/variables.scss";

.RegistrationSuccess {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: $space-small;
	&__image {
		margin-top: -100px;
		margin-bottom: 30px;
		position: relative;
		img {
			width: 150px;
			height: 150px;
			border-radius: $border-radius-big;
			background: white;
			box-shadow: $box-shadow;
		}
		.points {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 50px;
			height: 50px;
			padding-top: 8px;
			position: absolute;
			left: calc(50% - 25px);
			bottom: -20px;
			color: white;
			font-size: 10px;
			font-weight: 600;
			background: url("~img/points-icon.svg") no-repeat;
			background-size: cover;
		}
	}
	&__description1 {
		font-size: $font-size-medium;
		font-weight: 600;
	}
	&__description2 {
		display: flex;
		align-items: center;
		font-size: $font-size-ssmall;
		font-weight: 600;
		&:before {
			content:" ";
			display: inline-block;
			background: url("~img/points-icon.svg") no-repeat;
			background-size: cover;
			margin-right: $space-smallest;
			width: 19px;
			height: 19px;
		}
	}
	&__submit {
		width: 100%;
		margin-top: 30px;
	}
}