@import "scss/global/base/variables.scss";

.RegistrationInterests {
	height: 60vh;
	display: flex;
	flex-direction: column;
	gap: $space-small;
	position: relative;

	&__container {
		flex: 1;
		display: grid;
		gap: $space-smaller;
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
		overflow: auto;
	}

	&__item {
		height: 50px;
		padding: $space-smaller $space-small;
		color: #0078F4;
		text-transform: initial;
		font-weight: 400;
		font-size: $font-size-small;
		background: #F1F7FF;
		border-radius: $border-radius-small;
		filter: grayscale(100%);
		opacity: 0.7;

		&:hover {
			opacity: 0.9;
		}
		&.is-active {
			filter: grayscale(0%);
			opacity: 1;
		}
	}

	&__submit {
		//position: absolute;
		//bottom: 0;
		//left: 0;
		//right: 0;
		&:disabled,
		&:hover:disabled {
			color: white;
			background-color: #A1A4B1;
		}
	}
}