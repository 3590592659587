.cookie-consent {
  line-height:1.5;
  letter-spacing:.76px;
  p{
    margin:1em 0 .8em;
  }
  ul{
    display:flex;
    margin-bottom:1em;
    li{
      display:flex;
      margin:0;
      padding:0;
      align-items:center;
      &:not(:last-child) {
        margin-right:15px;
      }
      input{
        cursor:pointer;
      }
      label {
        padding:0;
        margin-left:5px;
        cursor:pointer;
      }
    }
  }
}