@import "scss/global/base/variables";

.timeline-content {
	padding: 2rem;

	.timeline {
		list-style: none;
		margin: 0;
		padding: 0 0 0 2.5rem;
		@media screen and (min-width:$medium-break) {
			margin: 0 0 0 5rem;
			border-left: 1px solid $border-gray;
		}
	}
}