@import "scss/global/base/variables";

.PersonalInterestsSelect {
	&__modal {
		padding: $space-medium;
	}
	&__content {
		display: flex;
		flex-direction: column;
		gap: $space-xlarge;
		width: 100%;
		height: 100%;
		overflow-y: auto;
		padding: $space-medium;
		border-radius: $border-radius-medium;
		@media screen and (min-width: $small-break) {
			width: 90vw;
			height: 90vh
		}
		@media screen and (min-width: $medium-break) {
			gap: $space-small;
			width: 80vw;
			height: 85vh
		}
		@media screen and (min-width: $large-break) {
			width: 40vw;
			height: 70vh
		}
	}
	&__title {
		display: block;
		text-align: center;
	}
	&__items {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
		grid-gap: $space-medium;
		overflow: auto;

		@media screen and (min-width: $medium-break) {
			grid-gap: $space-small;
		}

		&__item {
			padding: 0;

			.interest {
				filter: grayscale(100%);
				opacity: 0.4;
			}

			&:hover {
				.interest {
					opacity: 0.7;
				}
			}
		}

		.is-active {
			.interest {
				filter: grayscale(0%);
				opacity: 1;
			}
		}

	}
	&__buttons {
		margin-top: auto;
		display: flex;
		justify-content: space-between;
		.ok {
			flex-basis: 48%;
			margin-left: auto;
		}
	}
}
