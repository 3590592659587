@import "scss/global/base/variables.scss";

.RegistrationPhoto {
	display: flex;
	flex-direction: column;
	gap: $space-small;

	&__dropzone {
		display: flex;
		justify-content: center;
		height: 180px;
		&__img {
			width: 180px;
			height: 180px;
			object-fit: cover;
			border-radius: 44px;
		}
	}
}