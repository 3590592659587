@import "scss/global/base/variables";

.interest {
	text-transform: initial;
	padding: $space-smaller $space-small;
	font-size: $font-size-small;
	font-weight: 400;
	color: #0078F4;
	background: #F1F7FF;
	border-radius: $border-radius-big;

	&--add {
		color: $white;
		background: #0078F3;
	}
}