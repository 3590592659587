@import "variables";

$icomoon-font-path: "/img/fonts";

@font-face {
  font-family: "experly-icons";
  font-display: block;
  src: url("#{$icomoon-font-path}/experly-icons.woff2") format("woff2"),
    url("#{$icomoon-font-path}/experly-icons.ttf") format("truetype"),
    url("#{$icomoon-font-path}/experly-icons.woff") format("woff"),
    url("#{$icomoon-font-path}/experly-icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  &::before,
  &::after {
    font-family: experly-icons;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
  }
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  //width: 1.5rem;
  //height: 1.5rem;
  font-size: 19px;
  color: $ocean-dark;

  &.right-margin {
    margin-right: $space-smaller;
  }

  &.left-margin {
    margin-right: $space-smaller;
  }

  &-accommodation {
    &:before {
      content: $accommodationIcon;
    }
  }

  &-food_and_beverage {
    &:before {
      content: $foodIcon;
    }
  }

  &-transfer {
    &:before {
      content: $transferIcon;
    }
  }

  &-show_and_event {
    &:before {
      content: $showIcon;
    }
  }

  &-excursion {
    &:before {
      content: $excursionIcon;
    }
  }

  &-cruise {
    &:before {
      content: $cruiseIcon;
    }
  }

  &-other {
    &:before {
      content: $otherIcon;
    }
  }

  &-area {
    &:before {
      content: $areaIcon;
    }
  }

  &-poi {
    &:before {
      content: $poiIcon;
    }
  }

  &-bundle {
    &:before {
      content: $bundleIcon;
    }
  }

  &-room {
    &:before {
      content: "\e96c";
    }
  }
  &-user {
    &:before {
      content: "\e96d";
    }
  }
}

/*links-documents
**********************************/
.icon-pdf {
  &:before {
    content: "\e93c";
    color: $red-medium;
    margin-right: $space-smallest;
  }
}

.icon-chain {
  &::after {
    content: "\e927";
    font-size: 14px;
  }
}

.icon-url {
  &::before {
    content: "\e904";
  }
}

.icon-upload {
  &::before {
    content: "\e91d";
  }
}

.icon-video {
  &::before {
    font-size: 20px;
    margin-right: $space-smaller;
    vertical-align: middle;
    content: "\e923";
  }
}

/* main layout icons
*********************************************/
.icon-logo {
  background: linear-gradient(
          135deg,
          rgba(122, 127, 143, 1) 0%,
          rgba(122, 127, 143, 1) 35%,
          rgba(54, 59, 75, 1) 35%,
          rgba(54, 59, 75, 1) 50%,
          rgba(104, 109, 125, 1) 50%,
          rgba(104, 109, 125, 1) 65%,
          rgba(177, 182, 198, 1) 65%,
          rgba(177, 182, 198, 1) 100%
  );
  background-clip: text;
  color: transparent;
  &::before {
    content: "\e970";
  }
  &.active {
    background: linear-gradient(
            135deg,
            rgba(0, 177, 206, 1) 0%,
            rgba(0, 177, 206, 1) 35%,
            rgba(157, 0, 112, 1) 35%,
            rgba(157, 0, 112, 1) 50%,
            rgba(232, 56, 56, 1) 50%,
            rgba(232, 56, 56, 1) 65%,
            rgba(255, 180, 0, 1) 65%,
            rgba(255, 180, 0, 1) 100%
    );
    background-clip: text;
    color: transparent;
  }
}

.icon-search {
  &::before {
    content: "\e912";
    color: $gray-icon;
  }

  &.active {
    background: linear-gradient(
            135deg,
            rgba(0, 177, 206, 1) 0%,
            rgba(157, 0, 112, 1) 47%,
            rgba(232, 57, 55, 1) 65%,
            rgba(255, 180, 0, 1) 100%
    );
    background-clip: text;
    color: transparent;
    &::before {
      color: transparent;
    }
  }
}

.icon-shop {
  &::before {
    content: "\e96f";
    color: $gray-icon;
  }

  &.active {
    background: linear-gradient(
            135deg,
            rgba(0, 177, 206, 1) 0%,
            rgba(157, 0, 112, 1) 47%,
            rgba(232, 57, 55, 1) 65%,
            rgba(255, 180, 0, 1) 100%
    );
    background-clip: text;
    color: transparent;
    &::before {
      color: transparent;
    }
  }
}

.icon-switch-account::before {
  content: "\e97a";
}
.icon-logout::before {
  content: "\e979";
}
.icon-logout2::before {
  content: "\e925";
}
.icon-notification::before {
  content: "\e900";
}
.icon-notification-outlined::before {
  content: "\e97c";
}
.icon-favourite::before {
  content: "\e96a";
}
.icon-favourite-outlined::before {
  content: "\e934";
}
.icon-favourite-filled::before {
  content: "\e96a";
}
.icon-contribute::before {
  content: "\e972"
}
.icon-settings::before {
  content: "\e974";
}
.icon-settings2::before {
  content: "\e926";
}
.icon-settings3::before {
  content: "\e976";
}
.icon-help::before {
  content: "\e977";
}
.icon-book::before {
  content: "\e978";
}

/* various icons
*********************************************/
.icon-share::before {
  content: "\e96b";
}

/* CRUD icons
*********************************************/
.icon-plus,
.icon-add {
  &::before {
    content: "\e913";
    display: inline-block;
    font-size: 13px;
    vertical-align: baseline;
  }
}
.icon-add-after::after {
  content: "\e913";
  display: inline-block;
  font-size: 13px;
  vertical-align: baseline;
}

.icon-edit::before {
  content: "\e924";
}
.icon-edit-outlined::before {
  content: "\e97b";
}

.icon-delete::before {
  content: "\e914";
}
.icon-delete-after::after {
  content: "\e914";
}

/*boxes properties
*********************************************/

.icon-promotion {
  &::before {
    content: "\e937";
  }
}

.icon-length {
  &::before {
    content: "\e94a";
  }
}

.icon-ascending {
  &::before {
    content: "\e905";
    font-size: 16px;
  }
}

.icon-descending {
  &::before {
    content: "\e93d";
    font-size: 14px;
  }
}

/*icon close
**********************/

.icon-close {
  &::after {
    content: "\e91a";
  }
}

.icon-close--solo {
  font-size: 0;

  &:after {
    font-size: 17px;
  }
}

.icon-close--solo-big {
  font-size: 0;

  &:after {
    font-size: 22px;
  }
}
/*end icon close
**********************/


/*icon check
****************************/
.icon-check {
  &::after {
    content: "\e91c";
  }
}

.icon-check-before {
  &::before {
    content: "\e91c";
  }
}

/*icons -menu
****************************/
.icon-menu::before {
	content: "\e915";
	cursor: pointer;
	color: $font-primary;
	font-size: 14px;
	transition: $transition-std;
}
/*end icons menu
*****************************/

.icon-next {
  &::after {
    content: "\e90f";
    font-size: 18px;
  }
}

.icon-pin {
  &::before {
    content: "\e907";
  }
}

.icon-phone {
  &::before {
    content: "\e908";
  }
}

.icon-mail {
  &::before {
    content: "\e909";
  }
}

/*icon directions
************************/
.icon-previous {
  &::before {
    content: "\e910";
    float: left;
    font-size: 18px;
  }
}

.icon-previous--solo {
  font-size: 0;

  &:after {
    font-size: 17px;
  }
}

.icon-up {
  &::before {
    content: "\e90e";
  }
}

.icon-down {
  &::before {
    content: "\e911";
  }
}

.icon-down-big {
  &::before {
    content: "\e901";
  }
}
/*end icon directions
*************************/

.icon-connect {
  &::before {
    content: "\e91b";
  }
}

.icon-photo {
  &::before {
    content: "\e91f";
    vertical-align: middle;
  }
}

.icon-tag {
	&::before {
		content: "\e975"
	}
}

.icon-preview {
  &::before {
    content: "\e902";
  }
}

.icon-cruise {
  &::before {
    content: "\e929";
  }
}

.icon-date {
  &::before {
    content: "\e92a";
  }
}

.icon-world {
  &::before {
    content: "\e92b";
  }
}

.icon-fullscreen {
  &::before {
    content: "\e92c";
  }
}

.icon-clock {
  &::before {
    content: "\e92d";
  }
}

.icon-cash {
  &::before {
    content: "\e92e";
  }
}

.icon-cruise {
  &::before {
    content: "\e929";
  }
}

.icon-arrow-sign {
  &::before {
    content: "\e930";
  }
}

.icon-wifi {
  &::before {
    content: "\e931";
    display: inline-block;
  }
}

.icon-parking {
  &::before {
    content: "\e932";
  }
}

.icon-sorter {
  &::before {
    content: "\e90a";
  }
}

.icon-right {
  &::before {
    content: "\e90c";
  }
}

.icon-left {
  &::before {
    content: "\e90c";
    transform: rotate(180deg);
  }
}

.icon-global {
  &::before {
    content: "\e920";
  }
}

.icon-insurance {
  &::before {
    content: "\e921";
  }
}

.icon-loyalty {
  &::before {
    content: "\e922";
  }
}

.icon-management {
  &::before {
    content: "\e92f";
  }
}

.icon-network {
  &::before {
    content: "\e936";
  }
}

.icon-revenue {
  &::before {
    content: "\e938";
  }
}

.icon-instagram {
  &::before {
    content: "\e93f";
  }
}

.icon-statistics {
  &::before {
    content: "\e939";
  }
}

.icon-facebook {
  &::before {
    content: "\e93b";
  }
}

.icon-minus {
  &::before {
    content: "\e93e";
  }
}

.icon-twitter {
  &::before {
    content: "\e93a";
  }
}

/*accomodation amenities*/

.icon-apple {
  &::before {
    content: "\e94d";
  }
}

.icon-aircondition {
  &::before {
    content: "\e94b";
  }
}

.icon-asanser {
  &::before {
    content: "\e94e";
  }
}

.icon-bar {
  &::before {
    content: "\e94f";
  }
}

.icon-cuicine {
  &::before {
    content: "\e950";
  }
}

.icon-accessible {
  &::before {
    content: "\e969";
  }
}

.icon-draught {
  &::before {
    content: "\e952";
  }
}

.icon-midexpensive {
  &::before {
    content: "\e953";
  }
}

.icon-expensive {
  &::before {
    content: "\e954";
  }
}

.icon-eye {
  &::before {
    content: "\e955";
  }
}

.icon-flower {
  &::before {
    content: "\e956";
  }
}

.icon-kitchen {
  &::before {
    content: "\e957";
  }
}

.icon-croissoint {
  &::before {
    content: "\e958";
  }
}

.icon-wheelchair {
  &::before {
    content: "\e959";
  }
}

.icon-pet {
  &::before {
    content: "\e95a";
  }
}

.icon-reception {
  &::before {
    content: "\e95b";
  }
}

.icon-shower {
  &::before {
    content: "\e95c";
  }
}

.icon-shuttle {
  &::before {
    content: "\e95d";
  }
}

.icon-swimmer {
  &::before {
    content: "\e95e";
  }
}

.icon-tv {
  &::before {
    content: "\e95f";
  }
}

.icon-cheap {
  &::before {
    content: "\e960";
  }
}

.icon-washing {
  &::before {
    content: "\e961";
  }
}

.icon-people {
  &::before {
    content: "\e973";
  }
}

.icon-people2 {
	&::before {
		content: "\e940";
	}
}

.icon-speed {
  &::before {
    content: "\e941";
  }
}

.icon-harbor {
  &::before {
    content: "\e942";
  }
}

.icon-sea {
  &::before {
    content: "\e943";
  }
}

.icon-weight {
  &::before {
    content: "\e944";
  }
}

.icon-credit-card {
  &::before {
    content: "\e949";
  }
}

.icon-person {
  &::before {
    content: "\e945";
  }
}

.icon-window {
  &::before {
    content: "\e962";
  }
}

.icon-tip {
  &::before {
    content: "\e94c";
  }
}

.icon-map {
  &:before {
    content: "\e917";
  }
}

/* User Interests Icons
*********************************************/

.icon-film {
  &::before {
    content: "\e906";
  }
}

.icon-food {
  &::before {
    content: "\e919";
  }
}

.icon-sports {
  &::before {
    content: "\e935";
  }
}

.icon-travel {
  &::before {
    content: "\e951";
  }
}
