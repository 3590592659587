@import "scss/global/base/variables.scss";

.profile-search {
	padding: $space-small;
	border-radius: 5px;
	background: $gray-medium;
	&__form {
		display: flex;
	}
	&__input {
		flex: 1;
		width: 100%;
		border: none;
		border-radius: 5px;
		padding: $space-small;
	}
	&__submit {
		margin-left: $space-smaller;
	}
}