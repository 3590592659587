@import 'scss/global/base/variables';

.LayoutModalHeader {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: $space-external;
	position: relative;

	&__title {
		font-size: $font-size-xlarge;
		font-weight: 600;
	}
	&__back {
		position: absolute;
		left: $space-external;
	}
}