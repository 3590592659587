@import "scss/global/base/variables";

.NodeTeaser {
	$root: &;

	display: flex;
	gap: $space-small;
	cursor: pointer;
	&__img {
		object-fit: cover;
		border-radius: $border-radius-small;
	}
	&__details {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-around;
		@media screen and (min-width: $small-break) {
			gap: $space-smaller;
			justify-content: center;
		}
		&__title {
			width: 100%;
			font-weight: 600;
			text-align: left;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: $font-size-medium;
			@media screen and (min-width: $small-break) {
				font-size: $font-size-lmedium;
			}
		}
		&__location {
			color: $font-third;
			font-size: $font-size-small;
			@media screen and (min-width: $small-break) {
				font-size: $font-size-medium;
			}
		}
	}

	&--primary {
		#{$root}__img {
			width: 40px;
			height: 40px;
			@media screen and (min-width: $small-break) {
				width: 74px;
				height: 74px;
			}
		}
	}

	&--secondary {
		padding: $space-small;
		border-radius: 5px;
		box-shadow: $box-shadow;
		transition: $transition-std;
		&:hover {
			box-shadow: $box-shadow-intense;
		}
		#{$root}__img {
			width: 74px;
			height: 74px;
		}
	}

	&--secondary-no-border {
		#{$root}__img {
			width: 74px;
			height: 74px;
		}
	}
}