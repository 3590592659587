@import "scss/global/base/variables";

.profile-settings__linked-accounts {
  &__item {
    display:flex;
    justify-content: space-between;
    align-items: center;
    height:48px;
    &:not(:last-child) {
      margin-bottom:$space-medium;
    }
    &__inner {
      flex:1;
      margin-right:$space-small;
      display:flex;
      justify-content: space-between;
      align-items: center;
      border-bottom:2px solid $border-gray;
    }
    &__logo{
      font-size:0;
      width:150px;
      height:40px;
    }
    .FACEBOOK{
      background: url("../../../../../img/socials/facebook.png") no-repeat;
    }
    .GOOGLE{
      background: url("../../../../../img/socials/google.png") no-repeat;
    }
    .TWITTER{
      background: url("../../../../../img/socials/twitter.png") no-repeat;
    }
    &__button {
      width:132px;
    }
  }
}