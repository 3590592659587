@import "scss/global/base/variables";

.LayoutModal {
	//width:100vw;
	//height:100vh;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	&-appear {
		opacity: 0;
		overflow: hidden;
		pointer-events: none;
		transform: translatey(-10px);
	}

	&-appear-done {
		opacity: 1;
		overflow: visible;
		pointer-events: all;
		transform: translatey(0);
		transition: $transition-std;
	}

	&-exit {
		opacity: 1;
		overflow: visible;
	}

	&-exit-done {
		opacity: 0;
		transform: translatey(-10px);
		overflow: hidden;
		transition: $transition-std;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: $space-external;
		position: relative;

		&__title {
			font-size: $font-size-xlarge;
			font-weight: 600;
		}
		&__back {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 46px;
			height: 46px;
			background: $white;
			border-radius: 50%;
			position: absolute;
			left: $space-external;
			.icon {
				&:before {
					font-size: 30px;
					color: $ocean-light;
				}
			}
		}
	}

	&__main {
		height: 100%;
		overflow: auto;
		//&--full-width {
		//	padding: $space-large $space-external;
		//	@media screen and (min-width:$large-break) {
		//		padding: $space-large 0;
		//	}
		//}
		&--max-width &__inner {
			width:100%;
			margin:0 auto;
			max-width:$user-max-width;
			padding: $space-large $space-external;
			@media screen and (min-width:$large-break) {
				padding: $space-large 0;
			}
		}
	}

	&--with-header {
		background: #EBF0F6;
		display: flex;
		flex-direction: column;
		.LayoutModal__main {
			flex: 1;
			background: $white;
			border-top-left-radius: $border-radius-medium;
			border-top-right-radius: $border-radius-medium;
		}
	}
}