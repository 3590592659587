@import "../../../global/base/variables";

.modal-confirm{
  position:fixed;
  height:100vh;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  top:0;

  &:before{
    position:fixed;
    width:100%;
    height:100vh;
    top:0;
    left:0;
    background:black;
    content:"";
    opacity:0.4;
  }
  &__wrapper{
    background:white;
    position:relative;
    z-index:10;
    box-shadow:$box-shadow-intense;
    padding:$space-medium;
  }
  &__answer-wrapper{
    display:flex;
    justify-content: space-around;
    margin-top:$space-small;
  }
}

.is-modal-open.u-animate-state{
  opacity:1;
}