@import "scss/global/base/variables.scss";

.LoginForm {
	display: flex;
	flex-direction: column;
	gap: $space_large;
	@media screen and (min-width:$medium-break) {
		gap: $space_small;
	}

	&__forgot {
		text-align: right;
		font-size: $font-size-small;
	}

	&__join-with-social,
	&__register {
		font-size: 16px;
		text-align: center;
	}

	&__social {
		display:flex;
		justify-content: center;
		align-items: center;
		gap: $space-small;
		&__item {
			font-size:0;
			button {
				width: 60px;
				height: 60px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 0;
				cursor: pointer;
				&:before{
					font-family: 'experly-icons';
					-moz-osx-font-smoothing: grayscale;
					-webkit-font-smoothing: antialiased;
					font-style: normal;
					font-variant: normal;
					font-weight: normal;
					line-height: 1;
					speak: none;
					text-transform: none;
					color: white;
					font-size: 24px;
				}
			}
			&--facebook button{
				background-color:#3B5998;
				&:before{
					content:"\e93b";
				}
			}
			&--twitter button {
				background-color:#55ACEE;
				&:before{
					content:"\e93a";
				}
			}
			&--google button {
				background-image:url("../../../../img/socials/google+.svg");
				background-color:#e7e7e7;
				background-repeat:no-repeat;
				background-position:center center;
			}
		}
	}
}