@import "scss/global/base/variables.scss";

.JoinBusinessSupplierPartner {
	height: fit-content;
	display: flex;
	flex-direction: column;
	@media screen and (min-width:$medium-break) {
		flex-direction: row;
		justify-content: space-between;
		margin: $space-xxlarge;
	}
	@media screen and (min-width:$large-break) {
		justify-content: space-around;
		margin: 0;
	}

	&__supplier,
	&__partner {
		padding: $space-xlarge $space-external;
		line-height: 1.5;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 99;
		@media screen and (min-width:$medium-break) {
			padding: 0;
			max-width: 400px;
			justify-content: flex-start;
		}
		@media screen and (min-width:$large-break) {
			padding: 0;
			max-width: 500px;
			justify-content: flex-start;
		}
	}
	&__partner {
		color: white;
		background: $ocean-darker;
		@media screen and (min-width:$medium-break) {
			background: none;
		}
	}
	&__image {
		width: 150px;
		height: auto;
		@media screen and (min-width:$medium-break) {
			width: 180px;
		}
	}
	&__title {
		font-size: $font-size-heading2;
		margin: $space-large 0;
		@media screen and (min-width:$medium-break) {
			font-size: $font-size-heading1;
		}
	}
	&__description {
		text-align: center;
		line-height: 1.8;
		font-size: $font-size-msmall;
		margin: 0 0 $space-medium;
		@media screen and (min-width:$medium-break) {
			font-size: $font-size-medium;
		}
	}
	.next {
		width: 100%;
		@media screen and (min-width:$medium-break) {
			margin-top: auto;
		}
	}
}