@import "scss/global/base/variables";

.timeline-item-date {
	position: relative;
	padding: 0 0 $space-medium 0;

	h3 {
		margin: $space-small 0;
		top: 0;
		color: lighten($black, 73%);
		font-weight: normal;
		font-size: $font-size-ssmall;

		@media screen and (min-width:$medium-break) {
			margin: 0;
			position: absolute;
			left: -9.5rem;
			text-align: right;
			&:after {
				content: " ";
				height: 0.55rem;
				width: 0.55rem;
				background-color: $border-gray;
				border-radius: $border-radius-big;
				display: inline-block;
				position: absolute;
				top: 0;
				left: 6.70rem;
			}
		}
	}
}