@import "scss/global/base/variables";

$padding-button:14px 25px;

$padding-button-small:10px 15px;

/*new buttons
*************************/

button {
	outline: 0;
	color: $font-primary;
	background: transparent;
	border: none;
	cursor: pointer;
	&:disabled {
		color: rgba(0, 0, 0, 0.26);
		background-color: rgba(0, 0, 0, 0.12);
	}
}

/**
 * Variant Button
 */
.button-v-btn {
	font-weight: bold;
	font-size: $font-size-msmall;
	transition: $transition-std;
	border-radius: $border-radius-small;
	text-align: center;
	padding: $padding-button;
	cursor: pointer;
	display: inline-block;
	white-space: nowrap;

	&--round {
		border-radius: 50px;
	}

	&--small {
		font-weight: normal;
		padding: $padding-button-small;
		font-size: rem-calc(13);
	}

	&--icon {
		&:before {
				font-size: 20px;
				margin-right: $space-smaller;
		}
	}

	&--primary {
			color: white;
			background-color: $blue-medium;

			&:hover {
					background-color: $blue-darker;
			}
	}

	&--success {
			color: white;
			background-color: $green-medium;

			&:hover {
					background-color: $green-darker;
			}
	}

	&--warning {
			color: white;
			background-color: $red-medium;

			&:hover {
					background-color: $red-darker;
			}
	}

	&--secondary {
			color: $blue-medium;
			background-color: $gray-medium;

			&:hover {
					background-color: $gray-darker;
			}

			&-border {
					@extend .button-v-btn--secondary;
					border: 1px solid $blue-medium;

					&:hover {
							color: white;
							background-color: $blue-medium;
							border-color: transparent;
					}
			}
	}

	&--hollow {
		color: $blue-medium;
		background-color: transparent;
		border: 1px solid $blue-medium;
		&:hover {
			color: white;
			background-color: $blue-medium;
			border-color: transparent;
		}
	}
}

/**
 * Variant Link
 */
.button-v-link {
		&--primary {
				color: $blue-medium;

				&:hover {
						color: $blue-darker;
				}
		}
}

/**
 * Variant Nav Button
 */
.button-v-nav {
		width: 42px;
		height: 42px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		right: 10%;
		top: 50%;
		border-radius: 50%;
		font-size: 0;
		cursor: pointer;

		&:before {
				font-size: 23px;
		}

		&--primary {
				color: white;
				background-color: $blue-medium;

				&:hover {
						background-color: $blue-darker;
				}
		}
}

/*button close modal
**************************************/
.button-modal-close {
		position: absolute;
		right: 20px;
		top: 20px;
		font-size: 0rem;

		&:after {
				margin-left: 10px;
				display: inline-block;
				vertical-align: middle;
				font-size: 25px;
				color: #333;
				font-weight: bold;
				cursor: pointer;
		}
}

@media screen and (min-width:$medium-break) {
		.button-modal-close {
				display: none;
		}
}