@import "scss/global/base/variables.scss";

.JoinBusinessTerms {
	padding: $space-external;
	font-size: $font-size-msmall;
	line-height: 1.8;
	@media screen and (min-width:$medium-break){
		width: 40vw;
		height: 100%;
		overflow-y: auto;
	}
	h2 {
		//margin-top: $space-xxlarge;
		//@media screen and (min-width:$medium-break) {
		//	margin-top: $space-large;
		//}
	}
	p {
		margin: $space-xlarge 0;
		@media screen and (min-width:$medium-break) {
			margin-top: $space-large;
		}
	}
	.benefits-title {
		display: block;
		margin-bottom: $space-small;
	}
	.benefits {
		display: flex;
		flex-direction: column;
		gap: $space-small;
		&__item {
			display: flex;
			align-items: center;
			gap: $space-small;
			.icon {
				color: $ocean-light;
			}
		}
	}
	.submit {
		width: 100%;
	}
}